<template>
  <b-overlay :show="uploading">
    <template #overlay>
      <div class="text-center">
        <loading message="Importando partidas..."></loading>
      </div>
    </template>

    <b-form @submit.prevent="submit" class="momentosContables__form">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <b-form-group>
          <b-form-file
            v-model="payload.file"
            :state="Boolean(payload.file)"
            placeholder="Selecciona el archivo layout...">
          </b-form-file>
        </b-form-group>
      </div>

      <div class="my-2 d-flex">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

        <b-button
          type="submit"
          variant="success"
          class="ml-auto"
          size="sm"
          :disabled="!isFormValid"
        >
          Subir
        </b-button>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
const payload = () => ({
  file: null
})

const errors = () => ({
  rows: []
})

export default {
  name: 'ImportAnaliticoEgresosUnidadAdministrativaForm',

  data () {
    return {
      payload: payload(),

      errors: errors(),

      uploading: false
    }
  },

  computed: {
    isFormValid () {
      return [Boolean(this.payload.file)].every(e => e)
    }
  },

  methods: {
    async submit () {
      this.errors = errors()

      this.uploading = true
      const getval = await this.$store.dispatch('importAnaliticoEgresosUnidadAdministrativaModule/import', {
        file: this.payload.file
      })
      this.uploading = false

      //
      // Borra el formulario, exista error o no
      this.clearForm()

      if (getval.error) {
        //
        // Obtiene los errores, si es que los hay
        this.errors.rows = getval.data

        this.$alert(
          getval,
          'Importación de reporte de analítico de egresos por unidad administrativa.',
          'notificationNominasSidebarAlert',
          { status: 'danger' }
        )
      }

      //
      // Muestra mensaje de éxito y emite que ha terminado el proceso
      this.emitResult(getval)
    },

    clearForm () {
      this.payload = payload()
      this.errors = errors()
      this.$clearAlert()
    },

    emitResult (data) {
      this.$emit('on-result', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.momentosContables__form {
  max-width: 1200px;
  margin: auto;
  min-height: 25vh;
  border: 3px dashed pink;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
